import { getAnalytics } from '../helpers'
import { ClickEvent, clickEvents, ViewEvent, viewEvents } from './events'
import { useEffect } from 'react'

export const logEvent = (
  eventName: string,
  eventParams?: { [key: string]: any }
) => {
  const an = getAnalytics()
  an.logEvent(eventName, eventParams)
}

export const logClickEvent = (
  event: ClickEvent,
  params?: { [key: string]: any }
) => {
  logEvent(clickEvents[event], params)
}

/**
 * /pages直下のコンポーネントにしこむ
 * @param event
 */
export const useViewEvent = (
  event: ViewEvent,
  params?: { [key: string]: any }
) => {
  useEffect(() => {
    logEvent(viewEvents[event], params)
  }, [])
}
