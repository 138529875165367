import styled from '@emotion/styled'
import { colors } from '../../../styles/theme'

export const TwitterButton = styled.div`
  background: ${colors.twitter};
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
`

export const PinkButton = styled.div`
  cursor: pointer;
  background: #f197ad;
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
`
export const LightGrayButton = styled.div`
  background: ${colors.button.tertiary};
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
`

export const MonoButton = styled.div`
  background: transparent;
  border-radius: 20px;
  border: solid 2px ${colors.text.primary};
  font-weight: bold;
  font-size: 17px;
  color: ${colors.text.primary};
  text-align: center;
  // padding: 16px 0;
  cursor: pointer;
`

export const OrangeButton = styled.div`
  background: #fbccb2;
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  padding: 16px 0;
  cursor: pointer;
`

export const BlueButton = styled.div`
  background: #50abf1;
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
`

export const GoogleOrangeButton = styled.div`
  background: #cc5546;
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
`

export const GreenButton = styled.div`
  background: ${colors.button.secondary};
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
`

export const YellowButton = styled.div`
  background: ${colors.button.quaternary};
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
`

export const BlackButton = styled.div`
  background: #000000;
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
`

export const DiscordButton = styled.div`
  background: #4f76ff;
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
`
export const RedButton = styled.div`
  background: #ff4e4e;
  border-radius: 20px;
  font-weight: bold;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
`
