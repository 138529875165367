import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import Link from 'next/link'

import { colors } from '../../../styles/theme'
import { logClickEvent } from '../../../firebase/analytics'
import { useLocale } from '../../../hooks/useLocale'
import Image from 'next/image'

type Props = {}

export const AppBanner: React.FC<Props> = () => {
  const { t } = useLocale()
  const onClickInstallApp = useCallback(() => {
    logClickEvent('BannerInstallAppClick')
  }, [])
  return (
    <Container>
      <AppImage>
        <Image
          src="/static/images/icn_app_icon.png"
          alt="gameeロゴ"
          width={44}
          height={44}
          unoptimized={true}
        />
      </AppImage>
      <VerticalContainer>
        <DescriptionText>
          {t.アプリ版gameeはサクサク動くし通知も受け取れるよ}
        </DescriptionText>
        <HorizontalContainer>
          <Link
            href={'https://gameegames.page.link/5y6Q'}
            style={{
              textDecoration: 'none',
            }}
          >
            <InstallAppButton onClick={onClickInstallApp}>
              {t.インストール}
            </InstallAppButton>
          </Link>
          <Link
            href={'https://gameegames.page.link/5y6Q'}
            style={{
              textDecoration: 'none',
            }}
          >
            <OpenAppButton>{t.ひらく}</OpenAppButton>
          </Link>
        </HorizontalContainer>
      </VerticalContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 60px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f4f4f2;
  display: none;

  @media (max-width: 520px) {
    display: flex;
  }
`

const AppImage = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 8px;
  overflow: hidden;
`

const VerticalContainer = styled.div`
  margin-left: 8px;
  margin-top: 4px;
  flex-direction: column;
  justify-content: space-evenly;
`

const DescriptionText = styled.div`
  height: 20px;
  font-size: 11px;
  font-weight: bold;
  color: ${colors.text.primary};
  display: flex;
  align-items: center;
  line-height: 1.2;
`

const HorizontalContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  height: 24px;
  justify-content: flex-end;
`

const InstallAppButton = styled.div`
  padding: 8px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  overflow: hidden;
  color: #fff;
  background: ${colors.button.primary};
  display: flex;
  align-items: center;
`

const OpenAppButton = styled.div`
  margin-left: 12px;
  padding: 8px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  border: solid 1px ${colors.border.tertiary};
  overflow: hidden;
  color: ${colors.text.primary};
  background: #fff;
  display: flex;
  align-items: center;
`
